<template>
  <div class="flex align-items-center h-full px-3 cursor-pointer">
    <i
      v-if="noticount == 0"
      class="ri-notification-line ri-lg text-primary"
      @click="togglePanel"
    ></i>
    <i
      v-else
      class="ri-notification-line ri-lg text-primary"
      v-badge.danger="noticount"
      @click="togglePanel"
    ></i>
  </div>
  <OverlayPanel ref="userPanel" :style="{ width: '360px' }">
    <div class="p-0 m-0">
      <div
        class="p-4 bg-primary border-round-top-md w-full flex justify-content-between align-items-center"
      >
        <div class="text-white text-xl font-bold">Notification</div>
        <div class="flex align-items-center" v-if="notifications.length > 0">
          <!-- <i
            @click="markAsAll()"
            v-tooltip.top="'Mark as all'"
            class="ri-checkbox-multiple-line ri-lg cursor-pointer mr-3"
          ></i> -->
          <!-- <Avatar
            :label="noticount"
            style="background-color: var(--surface-0); color: var(--text-color)"
          /> -->
        </div>
      </div>
      <div class="noti-body">
        <TransitionGroup tag="ul" name="list" class="container">
          <div v-for="noti in notifications" :key="noti.id">
            <div
              class="p-4 flex align-items-center cursor-pointer"
              @click="gotoLink(noti.id, JSON.parse(noti.data).deep_link)"
            >
              <div class="w-full">
                <div class="flex justify-content-between flex-1">
                  <div class="text-lg font-bold">
                    {{ JSON.parse(noti.data).title }}
                  </div>
                  <div class="text-sm text-gray-500 flex-none">
                    {{ fromNow(noti.created_at) }}
                  </div>
                </div>
                <div class="flex justify-content-between mt-3">
                  <div class="text-lg mt-auto text-gray-600">
                    {{ JSON.parse(noti.data).message }}
                  </div>
                  <!-- <div class="mt-auto ml-3">
                    <i
                      v-if="noti.checked == 0"
                      @click="markCheck(noti.id)"
                      v-tooltip.top="'Mark as read'"
                      class="ri-mail-check-line ri-lg text-primary cursor-pointer mr-3"
                    ></i>

                    <i
                      @click="deleteNoti(noti.id)"
                      v-tooltip.top="'Remove'"
                      class="ri-delete-bin-line ri-lg cursor-pointer"
                      style="color: #fb7d7d"
                    ></i>
                  </div> -->
                </div>
              </div>
            </div>
            <Divider class="p-0 m-0" />
          </div>
        </TransitionGroup>
        <div v-if="!isLoading && notifications.length === 0">
          <div class="p-4 text-center cursor-pointer">
            <div class="pl-4 w-full">Empty</div>
          </div>
        </div>
        <div v-if="!lastPage" class="text-center p-4">
          <Button
            type="button"
            :label="isLoading ? 'Loading' : 'Load More'"
            class="p-button-rounded"
            :loading="isLoading"
            @click="fetchNotifications"
          />
        </div>
        <div v-else class="text-center p-4">You have reached the last page</div>
      </div>
    </div>
  </OverlayPanel>
</template>
<script>
import { defineComponent } from "vue"

import Button from "primevue/button"
import Divider from "primevue/divider"
import OverlayPanel from "primevue/overlaypanel"

import useNotification from "./useNotification"

export default defineComponent({
  name: "AppNotification",
  components: {
    Button,
    Divider,
    OverlayPanel
  },
  setup() {
    const {
      user,
      userPanel,
      noticount,
      notifications,
      isLoading,
      lastPage,
      togglePanel,
      userLogout,
      fromNow,
      markCheck,
      deleteNoti,
      fetchNotifications,
      notificationStore,
      gotoLink,
      markAsAll
    } = useNotification()

    return {
      user,
      userPanel,
      noticount,
      notifications,
      isLoading,
      lastPage,
      togglePanel,
      userLogout,
      fromNow,
      markCheck,
      deleteNoti,
      fetchNotifications,
      notificationStore,
      gotoLink,
      markAsAll
    }
  }
})
</script>
<style>
.user-menu {
  min-width: 100px;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}
.noti-body {
  height: 300px;
  overflow-x: scroll;
}
.container {
  position: relative;
  padding: 0;
  margin: 0;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
