<template>
    <div class="layout-footer">
        <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
        <span class="font-medium ml-2">Heal &copy; {{ new Date().getFullYear() }}</span>
    </div>
</template>

<script>
import EventBus from '@/libs/AppEventBus'
export default {
    name: 'AppFooter',
    components: {},
    data() {
        return {
            room: null,
            calling: false,
            user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : 'undefined'
        }
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme
        }
    },
    methods: {
        footerImage() {
            return this.$appState.darkTheme ? require('@/assets/images/logo-white.svg') : require('@/assets/images/logo-dark.svg')
        },
        showNoti(e) {
            console.log(e)
            EventBus.emit('show-call-notification', {
                avatar: e.avatar,
                name: e.name,
                id: e.roomName,
                chatId: e.chatId,
                appointmentId: e.appointmentId
            })
        }
    },
    mounted() {
        if (this.user !== 'undefined' && this.user.role == 'doctor') {
            window.Echo.private('comm.' + this.user.id).listen('.new.videocall', (e) => {
                if (this.$route.name != 'videoChatDetail') {
                    this.showNoti(e)
                } else {
                    EventBus.emit('doctor-in-call', {
                        appointmentId: e.appointmentId
                    })
                }
            })
            window.Echo.private('comm.' + this.appointmentId).listen('.doctor.action', (e) => {
                console.log(e)
            })
            window.Echo.private('comm.' + this.user.id).listen('.user.hangup', () => {
                EventBus.emit('hung-up', {
                    severity: 'error',
                    summary: '',
                    detail: 'Patient Hang Up!'
                })
                EventBus.emit('call-hung-up')
            })
        }
    }
}
</script>
