import { ref, onMounted, onUnmounted, onBeforeUnmount } from 'vue'
import { useAuthStore } from '@/modules/auth/authStore'
import { useRouter } from 'vue-router'

import { useStore as useNotificationStore } from '@/modules/notification/store'
import { fromNow } from '@/utils/formatter'
import EventBus from '@/libs/AppEventBus'
import ability from '@/libs/acl/ability'

export default function useNotification() {
    const authStore = useAuthStore()
    const router = useRouter()
    const userPanel = ref()
    const notifications = ref([])
    const noticount = ref(0)
    const user = authStore.getUserData
    const notificationStore = useNotificationStore()
    const page = ref(0)
    const perPage = ref(20)
    const lastPage = ref(false)
    const isLoading = ref(false)
    const notiId = ref(null)

    onMounted(() => {
        window.addEventListener('scroll', handleScroll)
        fetchNotifications()
        if (user.role == 'doctor') {
            window.Echo.private('notification.' + user.id).listen('.new.notification', (e) => {
                if (user.id == e.notification.notifiable) {
                    appendNoti(e)
                }
            })
        } else {
            window.Echo.private('notification.portal').listen('.new.notification', (e) => {
                if (user.corporate_ids.length == 0 && e.notification.notifiable == 'portal') {
                    const action = e.notification.permission.substring(0, e.notification.permission.indexOf('-'))
                    console.log(action)
                    if (ability.can(action, 'notifications')) {
                        appendNoti(e)
                    }
                }
            })
        }
    })

    onUnmounted(() => {
        window.addEventListener('scroll', handleScroll)
    })

    onBeforeUnmount(() => {
        authStore.$dispose()
        notificationStore.$dispose()
    })

    const togglePanel = (event) => {
        userPanel.value.toggle(event)
        if (noticount.value !== 0) {
            markAsAll()
        }
    }

    const userLogout = () => {
        authStore.logout()
        window.Echo.private('comm.' + user.id).leave()
        window.Echo.private('notification.' + user.id).leave()
        window.Echo.private('notification.portal').leave()
        window.Echo.channel('appointment').leave()
        router.replace({ name: 'login' })
    }

    const sound = require('@/assets/sounds/notification.mp3')
    const playNoti = () => {
        let notiSound = new Audio(sound)
        notiSound.addEventListener('canplaythrough', () => {
            notiSound.play()
        })
    }

    const appendNoti = (data) => {
        notifications.value = notifications.value.filter((val) => val.id !== data.notification.id)
        var newArr = notifications.value.slice()
        newArr.unshift(data.notification)
        notifications.value = newArr
        if (notifications.value[findIndexById(data.notification.id)].checked == 0) {
            noticount.value = noticount.value + 1
        }
        EventBus.emit('show-toast', {
            severity: 'info',
            summary: 'New notification !',
            detail: JSON.parse(data.notification.data).message
        })
        playNoti()
    }

    const fetchNotifications = async () => {
        isLoading.value = true
        page.value++
        //fetch API
        await notificationStore.fetchAll({
            page: page.value,
            per_page: perPage.value,
            request_from: 'portal'
        })
        //get response
        const response = notificationStore.getAllResponse
        //assign value
        if (response) {
            isLoading.value = false
            const data = response.data.notiList.data
            noticount.value = response.data.notiCount
            notifications.value.push(...data)
            lastPage.value = notifications.value.length >= response.data.notiList.total
        }
    }

    const markAsAll = async () => {
        await notificationStore.markAsAll()

        const response = notificationStore.getMarkAsAllResponse

        if (response) {
            noticount.value = 0
        }
    }

    const markCheck = async (id) => {
        await notificationStore.check({
            id: id
        })

        const response = notificationStore.getCheckResponse

        if (response) {
            notifications.value[findIndexById(id)].checked = 1
            if (notiId.value != id) {
                noticount.value = Math.max(noticount.value - 1, 0)
            }
            notiId.value = id
        }
    }

    const findIndexById = (id) => {
        let index = -1
        for (let i = 0; i < notifications.value.length; i++) {
            if (notifications.value[i].id === id) {
                index = i
                break
            }
        }

        return index
    }

    const deleteNoti = async (id) => {
        await notificationStore.delete({
            id: id
        })

        const response = notificationStore.getDeleteResponse

        if (response) {
            if (notifications.value[findIndexById(id)].checked == 1) {
                if (notiId.value != id) {
                    noticount.value = Math.max(noticount.value - 1, 0)
                }
            }
            notifications.value = notifications.value.filter((val) => val.id !== id)
            notiId.value = id
        }
    }

    const handleScroll = () => {
        if (userPanel.value) {
            userPanel.value.hide()
        }
    }

    const gotoLink = (id, path) => {
        if (path) {
            router.push({ path: '/' + path })
        }
    }

    return {
        user,
        userPanel,
        noticount,
        notifications,
        isLoading,
        lastPage,
        togglePanel,
        userLogout,
        fromNow,
        markCheck,
        deleteNoti,
        fetchNotifications,
        notificationStore,
        gotoLink,
        markAsAll
    }
}
